import React from "react";
import { useNavigate } from "react-router-dom";

import { LinkButton } from "@/components/commons/AppFormUI";
import Button from "@/images/button.png";

const ImageCard = ({ image, take, previewType, takePreview }) => {
  let navigate = useNavigate();

  const isImageVisibleForPreview = () => {
    if (previewType === "paid") {
      return false;
    } else {
      return image.paid_content;
    }
  };

  const showBlurBackground = () => {
    if (takePreview) {
      return isImageVisibleForPreview();
    } else {
      return image.has_view_access ? false : !image.blur_generated;
    }
  };

  const isBlurImageVisible = () => {
    if (takePreview) {
      return isImageVisibleForPreview();
    } else {
      return !image.has_view_access;
    }
  };

  const attachmentSrc = () => {
    return image.attachment_url || image.file_data;
  };

  if (!attachmentSrc()) {
    return null;
  }

  return (
    <div className="flex flex-col items-center w-full space-y-6">
      <div className="w-full relative backdrop-blur-md">
        <img
          src={attachmentSrc()}
          className="w-full h-auto"
          alt={image.alt_text || "Take Image"}
        />

        {showBlurBackground() && (
          <div className="absolute top-0 left-0 w-full h-full backdrop-blur-3xl"></div>
        )}

        {isBlurImageVisible() && (
          <div
            className="absolute bottom-2 left-2 sm:bottom-5 sm:left-5 z-[10] cursor-pointer"
            onClick={() =>
              navigate(take.generate_redirect_url, {
                replace: true,
              })
            }
          >
            <span className="text-white bg-black px-4 py-1.5 rounded-full text-[10px] sm:text-base">
              Subscribe to Unlock
            </span>
          </div>
        )}
      </div>

      {!isBlurImageVisible() && (
        <>
          <div
            className={`flex ${image.title?.length > 0 ? "justify-between" : "justify-end"} items-start w-full space-x-4`}
          >
            {image.title?.length > 0 && (
              <p
                className="pb-take--caption-text font-soehne-kraftig grow dark:text-white"
                dangerouslySetInnerHTML={{
                  __html: image.title?.split("\n")?.join("<br/>"),
                }}
              />
            )}

            {image.link?.length > 0 && !image.show_link_button && (
              <a href={image.link} target="_blank" className="shrink-0">
                <img
                  src={Button}
                  width="24px"
                  height="24px"
                  alt="link button"
                />
              </a>
            )}
          </div>

          {image.caption?.length > 0 && (
            <p
              className="pb-take--text w-full dark:text-white"
              dangerouslySetInnerHTML={{
                __html: image.caption?.split("\n")?.join("<br/>"),
              }}
            />
          )}

          {image.link?.length > 0 &&
            image.show_link_button &&
            image.button_text?.length > 0 && (
              <div className="flex justify-center !mt-8">
                <LinkButton
                  to={image.link}
                  label={image.button_text}
                  className="!w-fit !text-center"
                />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default ImageCard;
