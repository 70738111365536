import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import AuthLayout from "@/layouts/AuthLayout";
import HomeLayout from "@/layouts/HomeLayout";
import AppLayout from "@/layouts/AppLayout";
import DefaultLayout from "@/layouts/DefaultLayout";

import Home from "@/components/Home";
import About from "@/components/About";
import PrivacyPolicy from "@/components/PrivacyPolicy";
import TermsAndConditions from "@/components/TermsAndConditions";
import Login from "@/components/Authentication/Login";
import Signup from "@/components/Authentication/Signup";
import VerificationToken from "@/components/Authentication/VerificationToken";

import SubscribeAcknowledgement from "@/components/SubscribeAcknowledgement";
import UnsubscribeAcknowledgement from "@/components/UnsubscribeAcknowledgement";
import GenericErrorPage from "@/components/GenericErrorPage";
import NotFound from "@/components/commons/NotFound";
import SubscribeForm from "@/components/SubscribeForm";

import Discovery from "@/components/Dashboard/Discovery";
import CreateTake from "@/components/Dashboard/Take/CreateTake";
import TakeList from "@/components/Dashboard/Take/List";
import TakeDetail from "@/components/Dashboard/Take/Detail";
import Audience from "@/components/Dashboard/Audience";
import FeaturedBooth from "@/components/Dashboard/FeaturedBooth";
import VisualArchive from "@/components/Dashboard/VisualArchive";
import Search from "@/components/Dashboard/Search";
import Users from "@/components/Dashboard/SuperAdmin/Users";
import UserEdit from "@/components/Dashboard/SuperAdmin/Users/UserEdit";
import Discipline from "@/components/Dashboard/SuperAdmin/Disciplines";
import UnderReviewTakes from "@/components/Dashboard/SuperAdmin/UnderReviewTakes";

import AccountSettings from "@/components/Dashboard/Settings/AccountSettings";
import BillingSettings from "@/components/Dashboard/Settings/Billings";
import PiadSubscriptionSettings from "@/components/Dashboard/Settings/PiadSubscription";
import BrandingSettings from "@/components/Dashboard/Settings/Branding";
import EmbedSubscriberForm from "@/components/Dashboard/Settings/EmbedSubscriberForm";
import Profile from "@/components/Dashboard/Settings/Profile";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />}>
      <Route element={<HomeLayout />}>
        <Route element={<Home />} path="/" />
        <Route element={<About />} path="/about" />
        <Route element={<Login />} path="/login" />
        <Route element={<Signup />} path="/signup" />
        <Route element={<VerificationToken />} path="/verification_token" />
        <Route element={<TermsAndConditions />} path="/terms" />
        <Route element={<PrivacyPolicy />} path="/privacy" />
      </Route>

      <Route element={<AppLayout />}>
        <Route element={<Discovery />} path="/discovery" />
        <Route element={<TakeList />} path="/takes" />
        <Route element={<CreateTake />} path="/takes/new" />
        <Route element={<CreateTake />} path="/takes/:id/edit" />
        <Route element={<Audience />} path="/audience" />
        <Route element={<AccountSettings />} path="/settings/account" />
        <Route element={<BillingSettings />} path="/settings/billing" />
        <Route element={<BrandingSettings />} path="/settings/branding" />
        <Route
          element={<PiadSubscriptionSettings />}
          path="/settings/paid_subscription"
        />
        <Route
          element={<EmbedSubscriberForm />}
          path="/settings/embed_subscriber_form"
        />
        <Route element={<Profile />} path="/settings/profile" />

        <Route element={<Users />} path="/super_admin/users" />
        <Route element={<UserEdit />} path="/super_admin/users/:id/edit" />
        <Route element={<Discipline />} path="/super_admin/disciplines" />
        <Route element={<UnderReviewTakes />} path="/super_admin/takes" />
      </Route>

      <Route element={<DefaultLayout />}>
        <Route element={<SubscribeForm />} path="/:urlSlug" />
        <Route element={<FeaturedBooth />} path="/featured_booth" />
        <Route element={<Search />} path="/search" />
        <Route element={<VisualArchive />} path="/archive/:urlSlug" />
        <Route element={<TakeDetail />} path="/takes/:id" />
        <Route
          element={<UnsubscribeAcknowledgement />}
          path="/unsubscribed/success"
        />
        <Route element={<GenericErrorPage />} path="/unsubscribed/failure" />
        <Route
          element={<SubscribeAcknowledgement />}
          path="/subscribed/success"
        />
        <Route element={<GenericErrorPage />} path="/subscribed/failure" />
        <Route element={<NotFound />} path="/not_found" />
      </Route>
    </Route>,
  ),
);
