import React from "react";
import classnames from "classnames";

import {
  CheckboxIcon,
  CheckboxFillIcon,
  TickIcon,
} from "@/components/commons/PBIcons";

const PlanCard = ({ plan, selectedPlanId, setSelectedPlanId }) => {
  const currencySymbol = () => {
    if (plan.currency === "AUD") {
      return "A$";
    } else if (plan.currency === "USD") {
      return "$";
    }
  };

  const priceWithCurrency = () => {
    if (plan.interval === "free") {
      return "Free";
    } else {
      return `${currencySymbol()}${plan.unit_amount}/${plan.interval}`;
    }
  };

  return (
    <div
      className={classnames(
        "flex flex-col w-full gap-4 rounded-lg p-4 bg-[#F3EEE6] divide-y-[1px] divide-[#C8BFB4] font-soehne-mono ring-4 ring-transparent transition-all duration-300 ease-in-out cursor-pointer",
        {
          "!ring-primary !bg-white": selectedPlanId == plan.id,
        },
      )}
      onClick={() => setSelectedPlanId(plan.id)}
    >
      <div className="flex flex-col w-full gap-3">
        <div className="flex justify-between">
          <span className="text-lg">{plan.name}</span>
          {selectedPlanId == plan.id ? <CheckboxFillIcon /> : <CheckboxIcon />}
        </div>
        <span
          className={classnames(
            "inline-block rounded-full py-1.5 px-4 w-fit bg-white text-base transition-all duration-300 ease-in-out",
            {
              "!bg-primary": selectedPlanId == plan.id,
            },
          )}
        >
          {priceWithCurrency()}
        </span>
      </div>

      {plan.benefits?.length > 0 && (
        <div className="flex flex-col w-full gap-2 pt-4">
          {plan.benefits.map((benefit, index) => {
            return (
              <div key={index} className="flex w-full gap-2">
                <div className="shrink-0">
                  <TickIcon />
                </div>

                <span>{benefit}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PlanCard;
