import React from "react";
import { RouterProvider } from "react-router-dom";
import { Provider, ErrorBoundary } from "@rollbar/react";

import { registerLicense } from "@syncfusion/ej2-base";

import { router } from "@/components/Main";

function App() {
  // Registering Syncfusion license key
  registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);
  return (
    <Provider
      config={{
        accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
        environment: process.env.REACT_APP_ENVIRONMENT,
      }}
    >
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
